import { Link } from "gatsby"
import React from "react"
import yolcu from "../assets/img/companys/01.png"
import cetmen from "../assets/img/companys/02.png"
import ecanta from "../assets/img/companys/03.png"
import enza from "../assets/img/companys/04.png"
import konfor from "../assets/img/companys/05.png"
import kuhne from "../assets/img/companys/06.png"
import matbuu from "../assets/img/companys/07.png"
import oracle from "../assets/img/companys/08.png"
import pakmaya from "../assets/img/companys/09.png"
import robesnmore from "../assets/img/companys/10.png"
import sefastone from "../assets/img/companys/11.png"
import yatas from "../assets/img/companys/12.png"
import ttgv from "../assets/img/companys/14.png"
import tcdd from "../assets/img/companys/15.png"
import sierra from "../assets/img/companys/16.png"
import liva from "../assets/img/companys/17.png"
import jll from "../assets/img/companys/18.png"
import popupsmart from "../assets/img/companys/20.png"
import arcelik from "../assets/img/companys/21.png"
import stm from "../assets/img/companys/22.png"
import havelsan from "../assets/img/companys/50.png"
import tanoto from "../assets/img/companys/26.png"
import cagdas from "../assets/img/companys/24.png"
import cumhurb from "../assets/img/companys/32.png"
import unwomen from "../assets/img/companys/31.png"
import toyota from "../assets/img/companys/toyota-logo.png"
import userguidin from "../assets/img/companys/userguidin.webp"
import arksoft from "../assets/img/companys/arksoft.webp"
import simovate from "../assets/img/companys/simovate.webp"
import mehmetcik from "../assets/img/companys/mehmetcik_vakfi.png"
import pioli from "../assets/img/companys/pioli.png"
import fnss from "../assets/img/companys/fnss.png"
import akaliko from "../assets/img/companys/akaliko.png"






const Companys = () => (
    <div className="company-component">
        <div className="company-box">
            <img src={toyota} loading="lazy"/>
        </div>
       
        <div className="company-box">
            <img src={stm} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={arcelik} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={yolcu} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={enza} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={tcdd} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={cumhurb} loading="lazy"/>
        </div>
        
        <div className="company-box">
            <img src={unwomen} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={konfor} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={kuhne} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={matbuu} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={oracle} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={cetmen} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={robesnmore} loading="lazy"/>
        </div>
        
        <div className="company-box">
            <img src={havelsan} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={sefastone} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={yatas} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={pakmaya} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={ttgv} loading="lazy"/>
        </div>
       
        <div className="company-box">
            <img src={ecanta} loading="lazy"/>
        </div>
        
        <div className="company-box">
            <img src={sierra} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={liva} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={jll} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={cagdas} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={tanoto} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={simovate} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={userguidin} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={arksoft} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={mehmetcik} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={pioli} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={fnss} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={akaliko} loading="lazy"/>
        </div>
        <div className="company-box">
            <img src={popupsmart} loading="lazy"/>
        </div>
        
        
        




    </div>
)

export default Companys
